$(document).ready(function () {
   const nabvarBurger = document.querySelector('.navbar-burger');
   const sidebar = document.querySelector('#mysidebar');
   // Check for click events on the navbar burger icon
   $(".navbar-burger").click(function () {
      $(".navbar-burger").toggleClass("is-active");

      if (nabvarBurger.classList.contains('is-active')) {
         openNav();
         
      }
      else {
         closeNav();
      }

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      // $(".sidebar").toggleClass("is-hidden-touch");

   });

   /* Set the width of the side navigation to 250px and the left margin of the page content to 250px */
   function openNav() {
      sidebar.style.left = "0";
      sidebar.style.width = "100%";
      if (window.innerWidth > 768) {
         sidebar.style.width = "50%";
      }
      // sidebar.style.marginLeft = "300px";
      // document.getElementById("main").style.right = "-100%";
   }

   /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
   function closeNav() {
      sidebar.style.left = "-100%";
      if (window.innerWidth > 768) {
         sidebar.style.left = "-50%";
      }
      // document.getElementById("main").style.right = "0";
   }
});